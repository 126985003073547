/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import {SidebarMenuItem} from './SidebarMenuItem'

type AuthedUserType={
  user_type:string
}

type SelectorType ={
  userData?:string
}
const SidebarMenuMain = () => {
  const intl = useIntl()
  const {t} = useTranslation()
  const selector = useSelector<SelectorType>((state:any)=>state?.GlobalReducer?.userData)
  const selectors = useSelector<any>(state=>state?.GlobalReducer)
  const [newNumber ,setNewNumber]  =useState<any>({})
  const [authedUser,setAuthedUser] =useState<any>(null)
  // const selector = useSelector<SelectorType>((state:any)=>state?.GlobalReducer)
  
  useEffect(()=>{
    setNewNumber(selectors)
    setAuthedUser(selector)
  },[selectors])
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={t('Dashboard')} 
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{t('Orders')}</span>
        </div>
      </div>
      {
        authedUser?.user_type=='provider'&&
        <>          
          <div className='d-flex align-items-center justify-contetnt-between'>
            <SidebarMenuItem
              to='/apps/orders/all'
              icon='/media/icons/duotune/general/gen051.svg'
              title={t('Orders')}
              fontIcon='bi-layers'
            />
              {newNumber?.notifications?.order_new_number > 0  &&<span className="badge badge-success">{newNumber?.notifications?.order_new_number}</span>}
          </div>
          
          {/* <SidebarMenuItem
            to='/apps/provider-categories/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Provider Categories')}
            fontIcon='bi-layers'
          /> */}
          {
            authedUser?.isProviderAccountParent&&
            <>
                {
                  authedUser?.is_branch&&
                  <SidebarMenuItem
                    to='/apps/branches/all'
                    icon='/media/icons/duotune/general/gen051.svg'
                    title={t('Branches')}
                    fontIcon='bi-layers'
                  />
                }
                
                {/* <SidebarMenuItem
                  to='/apps/coupouns/all'
                  icon='/media/icons/duotune/general/gen051.svg'
                  title={t('Coupouns')}
                  fontIcon='bi-layers'
                /> */}
                <SidebarMenuItem
                  to='/apps/provider-settings/all'
                  icon='/media/icons/duotune/general/gen051.svg'
                  title={t('Restaurant Settings')}
                  fontIcon='bi-layers'
                />
                <SidebarMenuItem
                  to='/apps/provider-accounts/all'
                  icon='/media/icons/duotune/general/gen051.svg'
                  title={t('Provider Accounts')}
                  fontIcon='bi-layers'
                />
                <SidebarMenuItem
                  to='/apps/categories/all'
                  icon='/media/icons/duotune/general/gen051.svg'
                  title={t('Categories')}
                  fontIcon='bi-layers'
                />
            </>
          }
          <SidebarMenuItem
            to='/apps/products/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Products')}
            fontIcon='bi-layers'
          />
        </>
      }
      <SidebarMenuItem
        to='/apps/reports/all'
        icon='/media/icons/duotune/general/gen051.svg'
        title={t('Reports')}
        fontIcon='bi-layers'
      />
      {
        authedUser?.user_type=='admin'&&
        <>
          
          <div className='d-flex align-items-center justify-contetnt-between'>
            <SidebarMenuItem
              to='/apps/orders/all'
              icon='/media/icons/duotune/general/gen051.svg'
              title={t('Orders')}
              fontIcon='bi-layers'
            />
              {newNumber?.notifications?.order_new_number > 0  &&<span className="badge badge-success">{newNumber?.notifications?.order_new_number}</span>}
          </div>
          <SidebarMenuItem
            to='/apps/categories/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Categories')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/products/products-updates-requests/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Products Updates Requests')}
            fontIcon='bi-layers'
          />
          <div className='d-flex align-items-center justify-contetnt-between'>
            <SidebarMenuItem
              to='/apps/users/all'
              icon='/media/icons/duotune/general/gen051.svg'
              title={t('Users')}
              fontIcon='bi-layers'
            />
              {newNumber?.notifications?.user_new_number > 0  &&<span className="badge badge-success">{newNumber?.notifications?.user_new_number}</span>}
          </div>
          <div className='d-flex align-items-center justify-contetnt-between'>
            <SidebarMenuItem
              to='/apps/restaurants/all'
              icon='/media/icons/duotune/general/gen051.svg'
              title={t('Restaurants')}
              fontIcon='bi-layers'
            />
              {newNumber?.notifications?.provider_new_number > 0  &&<span className="badge badge-success">{newNumber?.notifications?.provider_new_number}</span>}
          </div>
          {/* <SidebarMenuItem
            to='/apps/users/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Users')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/restaurants/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Restaurants')}
            fontIcon='bi-layers'
          /> */}
          <SidebarMenuItem
            to='/apps/delivery-men/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Delivery Men')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/contact-us/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Contact Us')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/support-chat/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Support Chats')}
            fontIcon='bi-layers'
          />
          <div className='d-flex align-items-center justify-contetnt-between'>
            <SidebarMenuItem
              to='/apps/notifications/all'
              icon='/media/icons/duotune/general/gen051.svg'
              title={t('Notifications')}
              fontIcon='bi-layers'
            />
              {newNumber?.notifications?.new_number > 0 && <span className="badge badge-success">{newNumber?.notifications?.new_number}</span>}
          </div>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{t('Settings')}</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/apps/admin/users/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Admin Users')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/general-settings/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('General Settings')}
            fontIcon='bi-layers'
          />
          {/* <SidebarMenuItem
            to='/apps/whats-app-statistics/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Whatsapp Statistics')}
            fontIcon='bi-layers'
          /> */}
          <SidebarMenuItem
            to='/apps/static-pages/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Static Pages')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/advertisements/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Advertisements')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/countries/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Countries')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/coupouns/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Coupouns')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/wallets-prices/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Wallets Prices')}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/avatars/all'
            icon='/media/icons/duotune/general/gen051.svg'
            title={t('Avatars')}
            fontIcon='bi-layers'
          />
        </>
      }
    </>
  )
}

export {SidebarMenuMain}
